require("pickadate/lib/compressed/picker");
require("pickadate/lib/compressed/picker.date");
require("select2/dist/js/select2.full.min");
require("bootstrap-sass/assets/javascripts/bootstrap.min");
require("sweetalert/dist/sweetalert.min");
require("jquery-validation/dist/jquery.validate.min");
require("jquery.payment/lib/jquery.payment.min");
require("lodash");
if (!window.hideChat) {
  require("javascripts/vendor/zopim.min");
}
require("waypoints/lib/jquery.waypoints.min");
require("es6-promise/dist/es6-promise.auto.min");
require("javascripts/lib/ajaxCSRF");

window.moment = require("moment/moment");
